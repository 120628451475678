<template>
  <div>
    <remittances-report>

    </remittances-report>
  </div>
</template>
<script>
  import RemittancesReport from './PaymentCompanyReceivables'

  export default {
    components: {
      RemittancesReport,
    },
  }
</script>
